@import "./../../styles/vars.less";


.image-logo {
  width:  60px;
  display: inline-block;
  vertical-align : middle;
}
.logo-container {
  z-index: 2;
  height: @layout-header-height;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
};

.content-container {
  margin: 24px;
 // margin-top:  calc(@layout-header-height + 5px);
  box-sizing: border-box;
}
.header-container {
  z-index: 2;
//  position: fixed;
 width: 100%;
 padding: 0;
 background: #fff;
 box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}
.view-container{
  padding: 20px;
  background-color: white;
  // height: calc(100vh - @layout-header-height);
  min-height: calc(100vh - @layout-header-height);
  // background: #fff;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}


.menu-footer {
  height: 80px;
  padding: 5px;
  border-top: solid 1px @disabled-color;
}


.btn-collapse {
  position: absolute;
  right: -10px;

  font-size: 20px;
  z-index: 2;
  background: white;
  padding: 1px 3px;
  border-radius: 5px;
  box-shadow: 1px 1px 0px #b6a7a7, 1px 1px 0px #b6a7a7;
  &:hover {
    cursor: pointer;
  }
}

.btn-open-menu {
  position: fixed;
  left: 0;
  top: 55;
  z-index: 9999;
  color: #535E6C;
};

.avatar {
  // width: calc(@layout-header-height - 5px);
  // height: calc(@layout-header-height - 5px);
    width: 35px;
    height: 50px;
    padding-top: 16px;
    display: inline-block;
    vertical-align: middle;
}