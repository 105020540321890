.login-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-image: url('~/assets/images/bg_login.png');
  background-size: 100% 100%;
  & > .title {
    color: #eee;
    font-size: 26px;
    font-weight: 400;
    margin: 0px auto 30px auto;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
  }
  .spin-wrap {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    line-height: 90vh;
    z-index: 999;
  }
  .content {
    position: absolute;
    background-color: #fff;
    left: 50%;
    top: 50%;
    width: 30%;

    padding: 30px 30px 0 30px;
    transform: translate(-50%, -60%);
    box-shadow: 0 0 10px 2px rgba(40, 138, 204, 0.16);
    border-radius: 3px;

    .title {
      text-align: center;
      margin: 0 0 30px 0;
    }
    .two-button-wrap {
      display: flex;
      justify-content: space-between;
    }

    .row-container {
      display: flex;
      justify-content: space-between;
    }

    .user-type-button {
      width: 45%;
    }

    .login-form-button {
      width: 100%;
    }
    .login-form-forgot {
      float: right;
    }
  }
}
