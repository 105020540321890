// This file will contain all varibales, our custom varibales and
//those from Ant Design which we want to override.
@import "~antd/lib/style/themes/default.less";
// @primary-color: #181C75;
@primary-color: #3f51b5;
// @font-size-base: 16px;

@select-item-selected-option-color: @primary-color;
@processing-color: @primary-color;
@select-item-selected-bg: @background-color-base;
@secondary-color: @primary-color;
@skeleton-color: @primary-color;
@btn-primary-bg: @primary-color;
@layout-header-background: @background-color-base;

// @menu-item-font-size: @font-size-lg;

:root {
  --PC: @primary-color;
}
