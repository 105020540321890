

@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap");

@import "./styles/vars.less";
.ant-message {
	position: absolute;
	width: fit-content;
	top: 20px;
	bottom: initial;
	right: 20px;
	left: initial;
}
.ant-table-body {
	min-height: 65vh;
}
html {
  --scrollbarBG:  #fff;;
}
::-webkit-scrollbar {
  width: 12px;
}

.anticon svg {
  margin-bottom: 5px;
  display: inline-block;
}

body {
  scrollbar-width: thin;
  scrollbar-color: @disabled-color var(--scrollbarBG);
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  
  background-color: @disabled-color ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
} 
::-webkit-scrollbar-thumb:hover {
   background: @disabled-active-bg; 
}